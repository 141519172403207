import { useUser } from '@app/context/UserContext';
import { useSnackbar } from '@app/hooks/useSnackbar';
import { Button, Input } from '@components/ui';
import { useCreateScanResultMutation } from '@dieterApi/scan/useScanResultCreateMutation';
import { useScanResultQuery } from '@dieterApi/scan/useScanResultQuery';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Collapse } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScanResultDisplay } from './ScanResultDisplay';

export function TechnologyScan() {
  const { t } = useTranslation();
  const { user } = useUser();
  const [startUrl, setStartUrl] = useState<string>('');
  const [dpsUrl, setDpsUrl] = useState<string>('');
  const scanResult = user?.company.scanResult;
  const [formOpen, setFormOpen] = useState<boolean>(!scanResult);

  const [createScan, { data: createScanData, loading: createScanLoading, error }] = useCreateScanResultMutation();
  const [getResult, { data: scanResultData, loading: scanResultLoading, startPolling, stopPolling }] =
    useScanResultQuery();
  const { enqueueSnackbar } = useSnackbar();

  const loading = createScanLoading || scanResultLoading;

  // URL validation regex
  const urlRegex = /^(https?:\/\/)[\w-]+(\.[\w-]+)+.*$/;

  const handleScan = useCallback(
    (force: boolean = false) => {
      // Function to ensure URLs start with http:// or https://
      const ensureProtocol = (url: string) => {
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return `https://${url}`;
        }
        return url;
      };

      // Ensure URLs have the correct protocol
      const validatedStartUrl = ensureProtocol(startUrl);
      const validatedDpsUrl = dpsUrl ? ensureProtocol(dpsUrl) : '';

      // Validate URLs
      if (!urlRegex.test(validatedStartUrl) || (validatedDpsUrl && !urlRegex.test(validatedDpsUrl))) {
        enqueueSnackbar('Bitte geben Sie eine gültige URL ein.', { variant: 'error' });
        return;
      }
      setStartUrl(validatedStartUrl);
      setDpsUrl(validatedDpsUrl);

      // Proceed with the scan if URLs are valid
      createScan({ variables: { startUrl: validatedStartUrl, dpsUrl: validatedDpsUrl, force } });
      setFormOpen(false);
    },
    [startUrl, dpsUrl, createScan, enqueueSnackbar]
  );

  useEffect(() => {
    if (scanResult) {
      getResult({ variables: { propertyId: scanResult.propertyId } });
    }
  }, [scanResult]);

  useEffect(() => {
    if (scanResultData?.getScanResult.scanFinished && stopPolling) {
      stopPolling();
      setFormOpen(false);
      setStartUrl(scanResultData.getScanResult.startUrl);
      setDpsUrl(scanResultData.getScanResult.dpsUrl);
    }
  }, [scanResultData]);

  const handleRestart = () => {
    handleScan(true);
  };

  const noScansLeft = user?.company.numberOfScans === 0;

  const isDisabled = true;

  return (
    <div className="flex flex-col gap-5">
      <div className="flex items-center gap-8">
        <span className="text-base font-medium">{t('route.scanresult.heading')}</span>
        <button className="text-primary-root hover:text-primary-700" onClick={() => setFormOpen(!formOpen)}>
          {formOpen ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}
        </button>
      </div>
      <Collapse in={formOpen}>
        <div className="flex flex-col gap-4 mb-8">
          <p>{t('route.scanresult.description')}</p>

          <Input
            textSize="small"
            placeholder="URL der Startseite"
            onKeyDown={(e) => {
              e.key === 'Enter' && handleScan();
            }}
            value={startUrl}
            onChange={(e) => setStartUrl(e.target.value)}
          />
          <Input
            textSize="small"
            placeholder="URL der Datenschutzerklärung (optional)"
            value={dpsUrl}
            onChange={(e) => setDpsUrl(e.target.value)}
          />

          <p>
            <span className=" text-lg">Noch verfügbare Scans: </span>
            <span className="bg-gray-200 px-2 py-1 rounded-md text-lg text-gray-500">
              {user?.company.numberOfScans}
            </span>
          </p>
          {isDisabled && (
            <p className="bg-red-100 border-l-4 border-red-400 text-red-700 p-4" role="alert">
              Der Website-Scan steht derzeit aus technischen Gründen nicht zur Verfügung. Wir arbeiten mit Hochdruck an
              einer Lösung. Bitte versuche es später erneut.
            </p>
          )}

          <Button
            onClick={() => handleScan(false)}
            disabled={createScanLoading || user?.isReadOnly || noScansLeft || isDisabled}
            spinner={createScanLoading}
          >
            {t('route.scanresult.start_scan')}
          </Button>
        </div>
      </Collapse>
      {noScansLeft && (
        <p className="bg-red-100 border-l-4 border-red-400 text-red-700 p-4" role="alert">
          Du hast keine Scans mehr übrig. Bitte kontaktiere uns, um weitere Scans zu erwerben.
        </p>
      )}

      {(!!scanResult || loading) && (
        <ScanResultDisplay
          data={scanResultData?.getScanResult}
          startUrl={scanResult?.startUrl || startUrl}
          loading={loading}
          onRestart={handleRestart}
          noScansLeft={noScansLeft}
        />
      )}
    </div>
  );
}
