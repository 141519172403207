// mutation for creating an error

import { gql } from '@apollo/client';
import { ButtonDisplayLocation } from './updateSetting';

export const CONSENT_TEMPLATE_FRAGMENT = gql`
  fragment ConsentTemplateFields on SettingConsentTemplate {
    templateId
    categorySlug
    isHidden
    defaultConsentStatus
    description
    isDeactivated
    usesThirdCountry
    template {
      dataProcessor
    }
  }
`;

export const STYLES_FRAGMENT = gql`
  fragment StylesFields on Styles {
    btnPrivacyButtonInactiveSize
    historyDateFormat
    btnPrivacyButtonActiveSize
    txtOptInMsgFontSize
    btnPrivacyButtonTransparency
    btnPrivacyButtonBgColor
    btnAcceptTextColor
    btnDenyTextColor
    txtOptInMsgColor
    btnMoreInfoBgColor
    btnMoreInfoTextColor
    btnAcceptBgColor
    btnDenyBgColor
    linkColor
    bannerTextColor
    bannerBgColor
    privacyModalHeaderTextColor
    privacyModalHeaderBgColor
    cornerModalHeaderTextColor
    cornerModalHeaderBgColor
    btnPrivacyButtonTextColor
    modalSaveTextColor
    modalSaveBgColor
    chipTextColor
    chipBgColor
  }
`;

export const SETTING_FRAGMENT = gql`
  ${CONSENT_TEMPLATE_FRAGMENT}
  ${STYLES_FRAGMENT}
  fragment SettingFields on Setting {
    settingsId
    consentTemplates {
      ...ConsentTemplateFields
    }
    styles {
      ...StylesFields
    }
    customization {
      color {
        primary
        acceptBtnBackground
        denyBtnBackground
      }
    }
    privacyPolicyUrl
    imprintUrl
    partnerPoweredByLogoUrl
    partnerPoweredByUrl
    poweredByEnum
    enablePoweredBy
    buttonDisplayLocation
    googleConsentMode
    firstLayer {
      variant
    }
    language
    languageSelectorType
    languagesAvailable
    editableLanguages
  }
`;

export const GET_SETTING = gql`
  ${SETTING_FRAGMENT}
  query GetSetting($settingsId: String!, $language: String!) {
    setting(settingsId: $settingsId, language: $language) {
      ...SettingFields
    }
  }
`;

export interface GetSettingInput {
  settingsId: string;
  language: string;
}

export interface GetSettingResult {
  setting: UserCentricsSetting;
}

export interface UserCentricsSetting {
  settingsId: string;
  consentTemplates: ConsentTemplate[];
  privacyPolicyUrl: string;
  imprintUrl: string;
  customization: {
    color: {
      primary: string;
      acceptBtnBackground: string;
      denyBtnBackground: string;
    };
  };
  styles: Styles;
  partnerPoweredByLogoUrl: string;
  partnerPoweredByUrl: string;
  poweredByEnum: string;
  enablePoweredBy: boolean;
  buttonDisplayLocation: ButtonDisplayLocation;
  googleConsentMode: boolean;
  firstLayer: {
    variant: 'BANNER' | 'WALL';
  };
}

export interface ConsentTemplate {
  templateId: string;
  categorySlug: 'essential' | 'functional' | 'marketing';
  isHidden: boolean;
  defaultConsentStatus: string;
  description: string;
  isDeactivated: boolean;
  usesThirdCountry: boolean;
  template: {
    dataProcessor: string;
  };
}

export interface Styles {
  /** Small version of the button. When user "hovers" over it, it will increase to the default size e.g. 40 */
  btnPrivacyButtonInactiveSize?: number;

  /** Format of the date in history 0 => x minutes ago, 1 = > DD/MM/YY, H:mm, 2 => DD.MM.YY, H:mm, 3 =>  DD.MM.YYYY, H:mm */
  historyDateFormat?: number;

  /** Regular version of the button e.g. 80 */
  btnPrivacyButtonActiveSize?: number;

  /** Size of the text in optin banner in pixel(px) e.g. 16 */
  txtOptInMsgFontSize?: number;

  /** Degree of transparency of the button and the inactive banner in percent(%) e.g. 80 */
  btnPrivacyButtonTransparency?: number;

  /** Background color of the "Privacy Button" e.g. 411241 */
  btnPrivacyButtonBgColor?: string;

  /** Color of the text in the "accept" button e.g. 123641 */
  btnAcceptTextColor?: string;

  /** Color of the text in the "deny" button e.g. 123641 */
  btnDenyTextColor?: string;

  /** Color of optin msg in banner */
  txtOptInMsgColor?: string;

  /** Background color of the "more" button in the banner */
  btnMoreInfoBgColor?: string;

  /** Color of the "more" Text in the banner */
  btnMoreInfoTextColor?: string;

  /** Background color of the "accept" button e.g. 123641 */
  btnAcceptBgColor?: string;

  /** Background color of the "deny" button */
  btnDenyBgColor?: string;

  /** Color of all links with anchor tag and href attribute */
  linkColor?: string;

  /** Text color in the optin banner */
  bannerTextColor?: string;

  /** Background color of the banner e.g. 123641 */
  bannerBgColor?: string;

  /** Color of the header text in privacy modal */
  privacyModalHeaderTextColor?: string;

  /** Background Color of the header in privacy modal */
  privacyModalHeaderBgColor?: string;

  /** Color of the header text in corner modal @deprecated */
  cornerModalHeaderTextColor?: string;

  /** Background Color of the header in corner modal @deprecated */
  cornerModalHeaderBgColor?: string;

  /** Text color of the privacy button */
  btnPrivacyButtonTextColor?: string;

  /** Color of the "save" button in corner modal */
  modalSaveTextColor?: string;

  /** Background Color of the "save" button in corner modal */
  modalSaveBgColor?: string;

  /** Color of privacy chip */
  chipTextColor?: string;

  /** Background Color of privacy chip */
  chipBgColor?: string;
}
