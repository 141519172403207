import { gql } from '@apollo/client';
import { SETTING_FRAGMENT, Styles, UserCentricsSetting } from './getSetting';

export const UPDATE_SETTING = gql`
  ${SETTING_FRAGMENT}
  mutation Mutation(
    $language: String!
    $settingsId: String
    $imprintUrl: String
    $privacyPolicyUrl: String
    $languagesAvailable: [String]
    $customization: CustomizationInput
    $buttonDisplayLocation: String
    $firstLayer: FirstLayerInput
    $googleConsentMode: Boolean
    $styles: StylesInput
  ) {
    setting(
      language: $language
      settingsId: $settingsId
      imprintUrl: $imprintUrl
      privacyPolicyUrl: $privacyPolicyUrl
      customization: $customization
      buttonDisplayLocation: $buttonDisplayLocation
      firstLayer: $firstLayer
      googleConsentMode: $googleConsentMode
      styles: $styles
      languagesAvailable: $languagesAvailable
    ) {
      ...SettingFields
    }
  }
`;

export interface UserFacingUpdateSettingInput {
  imprintUrl?: string;
  privacyPolicyUrl?: string;
  buttonDisplayLocation: ButtonDisplayLocation;
  styles?: Styles;
  customization?: {
    color?: {
      primary?: string;
      acceptBtnBackground?: string;
      denyBtnBackground?: string;
    };
  };
  firstLayer: {
    variant: BannerVariants;
  };
  googleConsentMode?: boolean;
}

export type BannerVariants = 'BANNER' | 'WALL';

export type ButtonDisplayLocation = 'bl' | 'br';

export interface UpdateSettingInput extends UserFacingUpdateSettingInput {
  language: string;
  languagesAvailable: string[];
  settingsId: string;
}

export interface UpdateSettingResult {
  setting: UserCentricsSetting;
}
